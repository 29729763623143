<template>
	<div class="pa-5">
		<div
			class="text-h6 font-weight-bold"
		>
			개인정보 취급방침
		</div>
	<div
		class="mt-10"
		v-html="text"
	>
	</div>
	</div>
</template>

<script>
	import { TERMS } from '@/values/ValueTerms.js'
	export default{
		name: 'Personal'
		,created: function(){
			this.$emit('setProgram', this.program)
		}
		,data: function(){
			return {
				program: {
					not_title: true
					,not_navigation: true
				}
				,text: TERMS.PERSONAL
			}
		}
	}
</script>